import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setConsultantProfilePopup, setProfilePopup, setLoginPopup, setStudyInfoPopup } from "../../store/modules/popup"
import img_exploring_card_eyes from "../../images/img_exploring_card_eyes.svg"
import img_exploring_card_calendar from "../../images/img_exploring_card_calendar.svg"
import img_exploring_card_time from "../../images/img_exploring_card_time.svg"
import img_exploring_card_smile from "../../images/img_exploring_card_smile.svg"
import img_exploring_card_profile_icon from "../../images/img_exploring_card_profile_icon.svg"
import temp_profile from "../../images/editicon.png"
import moment from "moment"
import SignContainer from "../../containers/SignContainer"

const ColStudyItem = ({ data = {}, onClickStudy = () => {} }) => {
  // const { type, status, title, keywords, period, time, score, user } = data
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.sign.isLogin)
  const [participation, setParticipation] = useState([])
  const cardColor = {
    "취업/자격증": 5,
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    대입수험생: 4,
    공무원: 6,
    기타: 8,
    직장인: 7,
  }

  useEffect(() => {
    if (Object.keys(data).length != 0) {
      if (data.isParticipation != "") {
        setParticipation(data.isParticipation.split(" "))
      }
    }
  }, [data])

  return (
    <div className="col-lg-4 common-study-card-box">
      <div className={`card common-study-card common-study-card-${data.studentGroupName ? cardColor[data.studentGroupName] : 8}`}>
        <div className="common-card-top">
          <div>
            {participation.map((i, idx) => (
              <p className="common-card-top-p" key={idx}>
                {i}
              </p>
            ))}
          </div>
          <div className="d-flex align-items-center">
            <img src={img_exploring_card_eyes} alt="eyes img" />
            <p>{Number(data.viewCount || 1).toLocaleString()}</p>
          </div>
        </div>
        <p className="common-card-title">{data.title}</p>
        <p className="common-card-keywords">
          {data.tags.map((i, idx) => {
            let str = "#" + i + " "
            return str
          })}
        </p>
        <div className="common-card-study-info" onClick={() => dispatch(setStudyInfoPopup({ open: true, data }))}>
          <div className="common-card-study-info-calendar">
            <img src={img_exploring_card_calendar} alt="calendar img" />
            <p>
              {data.dateStart == data.dateEnd
                ? moment(data.dateStart).isValid() == true && moment(data.dateStart).format("MM/DD")
                : (moment(data.dateStart).isValid() == true && moment(data.dateStart).format("MM/DD")) +
                  "~" +
                  (moment(data.dateEnd).isValid() == true && moment(data.dateEnd).format("MM/DD"))}
            </p>
          </div>
          <div className="common-card-study-info-time">
            <img src={img_exploring_card_time} alt="time img" />
            <p>
              {data.isFree == "Y"
                ? "주1회 1시간"
                : `주${data.summaryStudySchedules != undefined ? data.summaryStudySchedules.weekStudyCount : ""}회 ${
                    data.summaryStudySchedules != undefined ? data.summaryStudySchedules.weekStudyHour : ""
                  }시간`}
            </p>
          </div>
          <div className="common-card-study-info-smile">
            <img src={img_exploring_card_smile} alt="smile img" />
            <p>{`${data.studyMemberCount || 0}/${data.studyMemberTotalCount || 0}`}</p>
          </div>
        </div>

        <div className="common-card-bottom">
          <div>
            <div
              className="common-card-bottom-avatar"
              onClick={() => {
                if (isLogin == true) {
                  if (data.managerUserType == "USER") {
                    dispatch(setProfilePopup({ open: true, data: data }))
                  } else if (data.managerUserType == "PARTNER") {
                    dispatch(setConsultantProfilePopup({ open: true, data: data }))
                  } else if (data.managerUserType == "ADMIN") {
                    return false
                  }
                } else {
                  dispatch(setLoginPopup(true))
                }
              }}
            >
              <img src={data.managerProfileUrl || temp_profile} alt="profile img" />
              <img src={img_exploring_card_profile_icon} alt="profile icon" />
            </div>

            <div className="common-card-bottom-user">
              <p>{data.managerNickname == "" ? "nickname" : data.managerNickname}</p>
              <p>{`${`${data.managerRegion1} ${data.managerRegion2} ${data.managerRegion3}` || ""} `}</p>
            </div>
          </div>
          <button className="btn btn-primary font-white" onClick={onClickStudy}>
            {data.isUsingPassword == "Y" && data.isOwner == "N" ? "비밀번호 요청" : "스터디 참여하기"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SignContainer(ColStudyItem)
