import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIntroPopup, setGatewayPopup } from "../store/modules/popup"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import LayerPopupLayout from "./LayerPopupLayout"
import img_main from "../images/img_main.svg"
import img_m_main from "../images/img_m_main.svg"
import img_main_play_btn from "../images/img_main_play_btn.svg"
import IntroContainer from "../containers/IntroContainer"
import YouTube from "react-youtube"
import { navigate } from "gatsby"
import { dataCheckFn, localStorgeGet, localStorgeSet } from "../utils/util"

const IntroPopup = props => {
  const { getIntroContents, introContents } = props
  const dispatch = useDispatch()
  const introPopup = useSelector(state => state.popup.introPopup)
  const [visible, setVisible] = useState(true)
  const videoRef = useRef()
  const videoIframeRef = useRef()
  const [utube, setUtube] = useState(null)
  const [idxNum, setidxNum] = useState(0)

  //인트로 데이터 get
  const getData = async () => {
    let result = await getIntroContents()
  }
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (introContents.length == 0) {
      setVisible(true)
    }
  }, [introContents])

  const _onReady = event => {
    setUtube(event)
  }

  // 비디오 시작 버튼
  const onClickIframeVideoPlay = e => {
    setVisible(false)
  }

  // 비디오 플레이중
  const onPlayIframeVideo = () => {
    setVisible(false)
  }

  // 비디오 멈춤
  const onPauseIframeVideo = e => {
    setVisible(true)
    utube.target.pauseVideo()

    // 비디오 재생위치 변경중
    // setVisible(false)
    // videoIframeRef.current.style.filter = "none"
  }
  // 비디오 시작 버튼
  const onClickVideoPlay = () => {
    if (!introContents.videoUrl.match(/(?:youtu|youtube)(?:\.com|\.be)\/([\w\W]+)/i)) {
      setVisible(false)
      videoRef.current.play()
      videoRef.current.style.filter = "none"
    } else {
      utube.target.playVideo()
    }
  }

  // 비디오 플레이중
  const onPlayVideo = () => {
    setVisible(false)
    videoRef.current.play()
    videoRef.current.style.filter = "none"
  }

  // 비디오 멈춤
  const onPauseVideo = e => {
    if (e.type === "pause") {
      // 비디오 멈춤
      setVisible(true)
      videoRef.current.pause()
      videoRef.current.style.filter = "brightness(40%)"
    } else if (e.type === "seeking") {
      // 비디오 재생위치 변경중
      setVisible(false)
      videoRef.current.style.filter = "none"
    }
  }
  const youtube_parser = url => {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    let match = url.match(regExp)
    return match && match[7].length == 11 ? match[7] : false
  }
  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      controls: 0,
      loop: 1,
      mute: 0,
      autoplay: typeof window != "undefined" && localStorgeGet("isVisited") != "true" ? 1 : 0,
    },
  }
  useEffect(() => {
    if (Object.keys(introContents).length > 0) {
      youtube_parser(introContents.videoUrl)
    }
  }, [introContents])
  // 렛미업 시작하기 버튼 이벤트
  const onClickStart = () => {
    dispatch(setIntroPopup(false))
    dispatch(setGatewayPopup(true))
    localStorgeSet("isVisited", "true")
  }

  return (
    <div style={{ width: "100%", height: "100%", zIndex: 103, display: introPopup ? "block" : "none" }}>
      {introPopup && (
        <LayerPopupLayout open={introPopup} zIndex={103}>
          <div className="container popup-intro-container">
            <div
              className="popup-intro-inner"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {Object.keys(introContents).length > 0 ? (
                introContents.fileType != "VIDEO" ? (
                  <div className="intro-img-box">
                    <Desktop>
                      <img onClick={() => navigate(introContents.pageUrl)} src={introContents.pcImageUrl} alt="" />
                    </Desktop>
                    <Mobile>
                      <img onClick={() => navigate(introContents.pageUrl)} src={introContents.mobileImgaeUrl} alt="" />
                    </Mobile>
                  </div>
                ) : (
                  <>
                    {introContents.videoUrl.match(/(?:youtu|youtube)(?:\.com|\.be)\/([\w\W]+)/i) ? (
                      // <iframe src={introContents.videoUrl}></iframe>
                      <div>
                        <div
                          onClick={() => {
                            if (idxNum % 2 == 0) {
                              utube.target.playVideo()
                            } else {
                              utube.target.pauseVideo()
                            }
                            setidxNum(idxNum + 1)
                          }}
                          style={{ zIndex: 9999, width: "100%", height: "60%", position: "absolute", display: "flex", flexShrink: 0 }}
                        ></div>
                        <YouTube
                          onClick={e => e.target.playVideo()}
                          onPlay={onClickIframeVideoPlay}
                          onPause={onPauseIframeVideo}
                          onEnd={onPauseIframeVideo}
                          // onStateChange={onPauseIframeVideo}
                          // ref={videoRef}
                          onReady={_onReady}
                          className={`iframe-box ${visible == true ? "iframe-box-off" : "iframe-box-on"}`}
                          // videoId="yHK7yCZa5hk"
                          videoId={youtube_parser(introContents.videoUrl)}
                          opts={opts}
                          id={"utube"}
                        ></YouTube>
                      </div>
                    ) : (
                      <video
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        // poster={img_main}
                        onPlaying={onPlayVideo}
                        onPause={onPauseVideo}
                        onEnded={onPauseVideo}
                        onSeeking={onPauseVideo}
                        controls
                        autoPlay
                        muted
                      >
                        <source type="video/mp4" src="https://va.media.tumblr.com/tumblr_o600t8hzf51qcbnq0_480.mp4" />
                      </video>
                    )}
                  </>
                )
              ) : (
                <div className="iframe-box iframe-box-off "></div>
              )}

              {visible && (
                <>
                  {dataCheckFn(introContents) && introContents.fileType == "VIDEO" && (
                    <button className="btn popup-intro-video-button" onClick={onClickVideoPlay}>
                      <img src={img_main_play_btn} alt="video play img" />
                    </button>
                  )}
                  <pre
                    className={`popup-intro-big-text ${
                      introContents.isDisplayText == "Y" ? `${introContents.textPositionHorizonType} ${introContents.textPositionVerticalType} ` : ""
                    }`}
                  >
                    {/* {`스마트하게 공부 경쟁력\n업그레이드`} */}
                    {introContents.text}
                  </pre>
                  <p className={`popup-intro-small-text ${introContents.isDisplayText == "Y" ? `CENTER MIDDLE ` : ""}`}>
                    {/* <p
                    className={`popup-intro-small-text ${
                      introContents.isDisplayText == "Y" ? `${introContents.textPositionHorizonType} ${introContents.textPositionVerticalType} ` : ""
                    }`}
                  > */}
                    {/* {introContents.text} */}
                    {/* <Desktop>{`모션 감지 기술을 활용한 렛미업 AI가 학습자의 움직임을 판단하여\n학습행동과 패턴을 분석해 드립니다.`}</Desktop>
                    <Mobile>{`모션 감지 기술을 활용한 렛미업 AI가\n학습자의 움직임을 판단하여 학습행동과\n패턴을 분석해 드립니다.`}</Mobile> */}
                  </p>
                  <button className="btn btn-primary font-white popup-intro-start-btn" onClick={onClickStart}>
                    <Desktop>렛미업 스터디 시작하기</Desktop>
                    <Mobile>다음</Mobile>
                  </button>
                </>
              )}
              {introContents.isPossibleSkip == "Y" && (
                <div className="d-flex justify-content-end mt-2 ">
                  <p onClick={() => onClickStart()} className="btn me-2">
                    {introContents.isPossibleSkip == "Y" ? introContents.skipComment : ""}
                  </p>
                </div>
              )}
            </div>
          </div>
        </LayerPopupLayout>
      )}
    </div>
  )
}

export default IntroContainer(IntroPopup)
