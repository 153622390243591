import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { setGatewayPopup } from "../store/modules/popup"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import LayerPopupLayout from "./LayerPopupLayout"
import IntroContainer from "../containers/IntroContainer"
import img_gateway_swiper_left from "../images/img_gateway_swiper_left.svg"
import img_gateway_swiper_right from "../images/img_gateway_swiper_right.svg"

import { navigate } from "gatsby"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const GatewayPopup = props => {
  const { gatewayContents, getGatewayContents, gatewayContentsMo } = props
  const dispatch = useDispatch()
  const gatewayPopup = useSelector(state => state.popup.gatewayPopup)
  const [ballIndex, setBallIndex] = useState(0)
  const [ballIndexMo, setBallIndexMo] = useState(0)

  // useEffect(()=>{
  //   if(gatewayContents.length==0){

  //   }
  // },[gatewayContents])

  useEffect(() => {
    if (gatewayPopup == true) {
      getData()
    }
  }, [gatewayPopup])

  const getData = async () => {
    await getGatewayContents()
  }

  const onClickAllPass = () => {
    // sessionStorage.setItem("IntroPopup", true)
    dispatch(setGatewayPopup(false))
  }

  return gatewayPopup ? (
    <LayerPopupLayout open={gatewayPopup} onClose={() => dispatch(setGatewayPopup(false))} isCloseBtn zIndex={103}>
      <div className="popup-gateway-container">
        <div className="popup-gateway-swiper-container">
          <button
            className="btn swiper-left"
            onClick={() => {
              if (ballIndex > 0) setBallIndex(prev => prev - 1)
            }}
          >
            <img src={img_gateway_swiper_left} alt="left img" />
          </button>
          <button
            className="btn swiper-right"
            onClick={() => {
              if (ballIndex < gatewayContents.length - 1) setBallIndex(prev => prev + 1)
            }}
          >
            <img src={img_gateway_swiper_right} alt="right img" />
          </button>
          <Desktop>
            {gatewayContents.length != 0 && (
              <AutoPlaySwipeableViews
                className="popup-gateway-swiper-view"
                enableMouseEvents
                autoPlay
                autoplay={gatewayContents[0].isAutoSlide == "Y" ? true : false}
                interval={gatewayContents[0].autoSlideInterval * 1000}
                index={ballIndex}
                onChangeIndex={index => setBallIndex(index)}
              >
                {gatewayContents.map((i, idx) => (
                  <div onClick={() => navigate(i.pageUrl)} key={idx}>
                    <img key={idx} className="gateway-img" src={i.pcImageUrl} draggable={false}></img>
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            )}
          </Desktop>
          <Mobile>
            {gatewayContentsMo.length != 0 && (
              <AutoPlaySwipeableViews
                className="popup-gateway-swiper-view"
                enableMouseEvents
                autoPlay
                autoplay={gatewayContentsMo[0].isAutoSlide == "Y" ? true : false}
                interval={gatewayContentsMo[0].autoSlideInterval * 1000}
                index={ballIndexMo}
                onChangeIndex={index => setBallIndexMo(index)}
              >
                {gatewayContentsMo.map((i, idx) => (
                  <div onClick={() => navigate(i.pageUrl)} key={idx}>
                    <img
                      key={idx}
                      className="gateway-img"
                      style={{ height: typeof window !== "undefined" ? window.innerHeight : "100%" }}
                      src={i.mobileImgaeUrl}
                      draggable={false}
                    ></img>
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            )}
          </Mobile>
        </div>
        <Desktop>
          <div className="popup-gateway-swiper-control-view">
            <div className="popup-gateway-swiper-pagination">
              {gatewayContents.length != 0 &&
                gatewayContents.map((i, idx) => (
                  <button
                    key={idx}
                    className={`btn popup-gateway-swiper-pagination-item ${ballIndex === idx ? "active" : ""}`}
                    onClick={() => setBallIndex(idx)}
                  ></button>
                ))}
            </div>
            <a className="popup-gateway-swiper-pass-btn" onClick={onClickAllPass}>
              {gatewayContents.length > 0 && gatewayContents[0].isPossibleSkip == "Y" && gatewayContents[0].skipComment}
            </a>
          </div>
        </Desktop>
        <Mobile>
          <div className="popup-gateway-swiper-control-view">
            <div className="popup-gateway-swiper-pagination">
              {gatewayContentsMo.length != 0 &&
                gatewayContentsMo.map((i, idx) => (
                  <button
                    key={idx}
                    className={`btn popup-gateway-swiper-pagination-item ${ballIndexMo === idx ? "active" : ""}`}
                    onClick={() => setBallIndexMo(idx)}
                  ></button>
                ))}
            </div>
            <a className="popup-gateway-swiper-pass-btn" onClick={onClickAllPass}>
              {gatewayContentsMo.length > 0 && gatewayContentsMo[0].isPossibleSkip == "Y" && gatewayContentsMo[0].skipComment}
            </a>
          </div>
        </Mobile>
      </div>
    </LayerPopupLayout>
  ) : (
    <div></div>
  )
}

export default IntroContainer(GatewayPopup)
