import axios from "axios"
import $ from "jquery"
const KAKAO_KEY = process.env.KAKAO_REST_KEY
const TMAP_KEY = process.env.TMAP_KEY

const kakaoFindAddrPostion = async param => {
  let kakaoKey = `KakaoAK ${KAKAO_KEY}`
  const result = await axios({
    method: "GET",
    url: `https://dapi.kakao.com/v2/local/search/address?query=${param[0]}`,
    headers: {
      Accept: "application/json",
      Authorization: kakaoKey,
    },
  })

  return result.data.documents[0]
}

const getDongList = async param => {
  let list = []

  const result = $.ajax({
    method: "GET",
    url: `https://apis.openapi.sk.com/tmap/pois?version=1&page=1&count=200&searchType=name&searchKeyword=주민센터&resCoordType=WGS84GEO&searchType=all&searchtypCd=R&radius=${param.km}&reqCoordType=WGS84GEO&centerLon=${param.lon}&centerLat=${param.lat}&appKey=${TMAP_KEY}`,
    async: false,
    success: function (response) {
      if (response) {
        if (!response) list = []
        else if (response.searchPoiInfo) list = response.searchPoiInfo.pois.poi.filter(i => i.lowerBizName === "동/읍/면/리사무소")
      }
    },
    error: function (request, status, error) {
      console.log("code:" + request.status + "\n message:" + request.responseText + "\n error:" + error)
    },
  })

  return list
}

export default {
  kakaoFindAddrPostion,
  getDongList,
}
