import React, { useState, useEffect } from "react"
import { debounce } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import Validator from "validator"
import CloseBtn from "../images/Close_Button.svg"

const ModalPwReqPopup = props => {
  const { open = false, data = {}, onClose = () => {}, reqFn = () => {}, joinFn = () => {}, setPw = () => {}, pw = "" } = props
  const dispatch = useDispatch()
  const [errText, setErrText] = useState("")
  const [errAlert, setErrAlert] = useState(false)

  useEffect(() => {
    setErrText("")
    setErrAlert(false)
  }, [open])

  return open == true ? (
    <div onClick={onClose} className="popup-modal-pwReq-container" style={{ zIndex: 999 }}>
      <div onClick={e => e.stopPropagation()} className="popup-modal-pwReq-div">
        <div className="popup-modal-pwReq-close-div">
          <img src={CloseBtn} onClick={onClose}></img>
        </div>
        <div className="popup-modal-pwReq-contents-div">
          <p>비밀번호</p>
          <input className="modal-input" type="text" placeholder="Password" value={pw} onChange={e => setPw(e.target.value)}></input>
        </div>
        {errAlert && (
          <div style={{ marginTop: "1px", width: "95%" }} className="alert alert-danger">
            {errText}
          </div>
        )}
        {
          <div className="popup-modal-pwReq-btn-div">
            <button
              onClick={debounce(() => {
                reqFn()
              }, 500)}
              className="btn btn-primary reverse"
            >
              {"비밀번호 요청하기"}
            </button>
            <button
              onClick={debounce(async () => {
                let result = await joinFn()
                if (result == false) {
                  setErrText("잘못된 비밀번호입니다.")
                  setErrAlert(true)
                }
              }, 500)}
              className="btn btn-primary font-white"
            >
              {"입장하기"}
            </button>
          </div>
        }
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default ModalPwReqPopup
