/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby-link"
import { useDispatch, useSelector } from "react-redux"
import {
  setIntroPopup,
  setRankingPopup,
  setJitsiPopup,
  setLoginPopup,
  setSignUpPopup,
  setStudyMakePopup,
  setProfilePopup,
  setConsultantProfilePopup,
  setStudyInfoPopup,
  setJitsiClose,
  setBrainwavePopup,
} from "../store/modules/popup"
import { setAttention, setMeditation } from "../store/modules/jitsiInfo"
import Swiper from "react-id-swiper"
import "swiper/scss"
import { setJitsiInfo } from "../store/modules/jitsiInfo"
import SwipeableViews from "react-swipeable-views"
import SEO from "../components/seo"
import { Desktop, Mobile, NonDesktop, Tablet } from "../components/layouts/mediaQuery"
import Layout from "../components/Layout"
import ColStudyItem from "../components/common/ColStudyItem"
import RankItem from "../components/exploring/RankItem"
import IntroPopup from "../popups/IntroPopup"
import GatewayPopup from "../popups/GatewayPopup"
import RankingPopup from "../popups/RankingPopup"
import ModalPwReqPopup from "../popups/ModalPwReqPopup"
import img_exploring_filter from "../images/img_exploring_filter.svg"
import img_exploring_bottom_button_1 from "../images/img_exploring_bottom_button_1.svg"
import img_exploring_bottom_button_2 from "../images/img_exploring_bottom_button_2.svg"
import img_exploring_bottom_button_3 from "../images/img_exploring_bottom_button_3.svg"
import img_exploring_bottom_button_4 from "../images/img_exploring_bottom_button_4.svg"

import img_schedule_left_white from "../images/img_schedule_left_white.svg"
import img_schedule_right_white from "../images/img_schedule_right_white.svg"
import SignContainer from "../containers/SignContainer"
import MyContainer from "../containers/MyContainer"
import StudyMakeContainer from "../containers/StudyMakeContainer"
import ExploringContainer from "../containers/ExploringContainer"
import IntroContainer from "../containers/IntroContainer"
import Validator from "validator"
import addrApis from "../apis/AddrApis"
import api from "../utils/api"
import { debounce } from "lodash"
import ModalPopup from "../popups/ModalPopup"
import ModalPointPopup from "../popups/ModalPointPopup"
import moment from "moment"
import ConsultantToDoItem from "../components/my/ConsultantToDoItem"
import { autoPlay } from "react-swipeable-views-utils"
import rollingTopPc1 from "../images/rollingTopPc.png"
import cardPc from "../images/cardPc.png"
import SignApis from "../apis/SignApis"
import { localStorgeGet, localStorgeSet } from "../utils/util"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Exploring = props => {
  const {
    horizonBottomPc,
    horizonBottomMo,
    getPromotionContents,
    getMyProfileAction,
    postStudyPasswordCheck,
    isLogin,
    checkSignStatusExploring,
    getStudyhome,
    exploringData,
    rollingTopPc,
    rollingTopMo,
    rollingBottomPc,
    rollingBottomMo,
    cardHoldPc,
    cardHoldMo,
    putContentsPromotionView,
    postPwReq,
  } = props

  const rankingPopup = useSelector(state => state.popup.rankingPopup)
  const studyMakePopup = useSelector(state => state.popup.studyMakePopup)

  const profilePopup = useSelector(state => state.popup.attention)
  const profilePopupOpen = useSelector(state => state.popup.profilePopup.opne)
  const jitsiClose = useSelector(state => state.popup.jitsiClose)
  const jitsiPopup = useSelector(state => state.popup.jitsiPopup)
  const studyInfoPopupOpen = useSelector(state => state.popup.studyInfoPopup.open)
  const loginPopup = useSelector(state => state.popup.loginPopup)
  const signUpPopup = useSelector(state => state.popup.signUpPopup)
  const consultantProfilePopupOpen = useSelector(state => state.popup.consultantProfilePopup.open)

  const [swiperIndex, setSwiperIndex] = useState(0)
  const [swiperIndexTopAd, setSwiperIndexTopAd] = useState(0)
  const [swiperIndexBottomAd, setSwiperIndexBottomAd] = useState(0)
  const [keyword, setKeyword] = useState("")
  const [location, setLocation] = useState("")
  const [top100, setTop100] = useState({ list: [], title: "", type: "" })
  const [openPwReqPopup, setOpenPwReqPopup] = useState({ open: false, data: {} })
  const [pw, setPw] = useState("")
  const dispatch = useDispatch()
  const [LocArr, setLocArr] = useState([])
  const [openClosePopup, setOpenClosePopup] = useState(false)
  const [inviteCheck, setInviteCheck] = useState(false)
  const [empty, setEmpty] = useState(moment())
  const [rollingTopPcList, setRollingTopPcList] = useState([])
  const [rollingTopMoList, setRollingTopMoList] = useState([])
  const [rollingBottomPcList, setRollingBottomPcList] = useState([])
  const [rollingBottomMoList, setRollingBottomMoList] = useState([])

  const [brainwave, setBrainwave] = useState(false)
  const [brainStartTime, setbrainStartTime] = useState(moment())

  const [brainwaveVal, setBrainwaveVal] = useState(10)
  const attention = useSelector(state => state.jitsiInfo.attention)
  const meditation = useSelector(state => state.jitsiInfo.meditation)

  //data get
  const getData = debounce(async () => {
    let temp = { region3: location == "전체" ? "" : location, tag: keyword }
    await getStudyhome(temp)
  }, 500)

  //롤링 광고 정리
  const idxFn = arr => {
    let temp = []
    for (let i = 0; i < arr.length; i++) {
      if (i != 0) {
        temp = [...temp, arr[i]]
      }
    }
    return temp
  }

  //롤링 광고 정리
  useEffect(() => {
    setRollingTopPcList(idxFn(rollingTopPc))
    setRollingTopMoList(idxFn(rollingTopMo))
    setRollingBottomPcList(idxFn(rollingBottomPc))
    setRollingBottomMoList(idxFn(rollingBottomMo))
  }, [rollingBottomMo])

  //초기화
  const initState = () => {
    setLocation("")
    setKeyword("")
  }
  //로그인 체크
  const checkLoginFn = async () => {
    let deviceNumber = ""
    let test = navigator.userAgent
    let arr = test.split(" ")
    let token = ""
    for (let i of arr) {
      if (i.indexOf("token_") != -1) {
        token = i.split("oken_")[1]
      } else if (i.indexOf("deviceNumber") != -1) {
        deviceNumber = i.split("Number")[1]
      }
    }

    await checkSignStatusExploring({ token: token, deviceNumber: deviceNumber })
  }

  //유저 지역 저장여부 체크
  const addrCheckFn = () => {
    let studyLocation = localStorgeGet("studyLocation") && JSON.parse(localStorgeGet("studyLocation"))
    if (exploringData.userStudyLocations.length == 0) {
      setLocArr([])
      return false
    }
    if (
      studyLocation != undefined &&
      studyLocation.userSeq == localStorgeGet("loginUserSeq") &&
      studyLocation.studyLoc == exploringData.userStudyLocations[0]
    ) {
      setLocArr(studyLocation.locArr)
    } else {
      addrFn(exploringData.userStudyLocations)
    }
  }

  //주변동 검색
  const addrFn = debounce(async data => {
    let x = 0
    let y = 0

    const result = await addrApis.kakaoFindAddrPostion(data)

    x = result.x
    y = result.y

    let result2 = await addrApis.getDongList({ lon: x, lat: y, km: 3 })
    let temp = []
    for (let i = 0; i < result2.length; i++) {
      if (result2[i].detailBizName == "주민센터") {
        temp = [...temp, result2[i].lowerAddrName]
      }
    }
    let set = new Set(temp)
    temp = [...set]

    localStorgeSet(
      "studyLocation",
      JSON.stringify({
        userSeq: localStorgeGet("loginUserSeq"),
        studyLoc: exploringData.userStudyLocations[0],
        locArr: temp,
      })
    )
    setLocArr(temp)
  }, 500)

  //초대링크로 방입장
  const inviteFn = async () => {
    if (props.location.search == "" || props.location.search == undefined) return false
    let loginCheck = await checkSignStatusExploring({})
    // 로그인 체크
    if (!loginCheck) {
      dispatch(setLoginPopup(true))
      //비로그인으로 입장불가
      setInviteCheck(true)
      return false
    }
    let temp = props.location.search
    let tempArr = temp.split("&")
    let seq = ""
    let inviter_ = ""
    let isInvite_ = "N"
    for (let i of tempArr) {
      if (i.indexOf("studySeq") != -1) {
        seq = i.split("=")[1]
      }
      if (i.indexOf("isInvite") != -1) {
        isInvite_ = i.split("=")[1]
      }
      if (i.indexOf("inviter") != -1) {
        inviter_ = i.split("=")[1]
      }
    }
    const studyCheck = await api.get(`/study/invitation/check?studySeq=${seq}`, { token: true, body: {} })
    localStorage.removeItem("inviteStudy")

    if (studyCheck.data.code == 0) {
      setInviteCheck(false)

      let data = studyCheck.data.data
      if (data.studyInvitationCheck.isUsingPassword == "Y") {
        setOpenPwReqPopup({ open: true, data: { studySeq: seq, isInvite: isInvite_, inviter: inviter_ } })
      } else {
        const rq_result = await api.post(`/study/participation`, {
          token: true,
          body: { studySeq: seq, name: "", isInvite: isInvite_, inviter: inviter_ },
        })
        if (rq_result.status === 200) {
          dispatch(
            setJitsiInfo({
              name: "",
              seq: seq,
              roomInfo: rq_result.data.data.studyMemberUserInfo,
              aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
            })
          )
          dispatch(setJitsiPopup(true))
        }
      }
      navigate("/exploring")
    }
  }

  //스터디참여 pw체크 방입장
  const joinPwCheckFn = async () => {
    if (Validator.isEmpty(openPwReqPopup.data.studySeq, { ignore_whitespace: true }) || Validator.isEmpty(pw, { ignore_whitespace: true })) {
      return false
    }
    let temp = { studySeq: openPwReqPopup.data.studySeq, password: pw }
    let result = await postStudyPasswordCheck(temp)
    if (result) {
      const rq_result = await api.post(`/study/participation`, {
        token: true,
        body: {
          studySeq: openPwReqPopup.data.studySeq,
          isInvite: openPwReqPopup.data.isInvite ? openPwReqPopup.data.isInvite : "N",
          inviter: openPwReqPopup.data.inviter ? openPwReqPopup.data.inviter : "",
        },
      })
      if (rq_result.status === 200) {
        dispatch(
          setJitsiInfo({
            name: openPwReqPopup.data.title,
            seq: openPwReqPopup.data.studySeq,
            roomInfo: rq_result.data.data.studyMemberUserInfo,
            aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
          })
        )
        dispatch(setJitsiPopup(true))
      }
      setOpenPwReqPopup({ open: false, data: {} })
      setPw("")
    }
    return result
  }
  //비밀번호 요청
  const reqFn = async () => {
    await postPwReq({ studySeq: openPwReqPopup.data.studySeq })
  }
  useEffect(() => {
    if (window != undefined) {
      window.addEventListener(
        "load",
        function () {
          setTimeout(scrollTo, 0, 0, 1)
        },
        false
      )
    }
    //초대 링크 확인
    const isInvited = props.location.search.includes("studySeq")
    if (isInvited) inviteFn()
    else {
      let isVisited = localStorgeGet("isVisited")
      if (isVisited == undefined || isVisited == "false") {
        // if (true) {
        dispatch(setIntroPopup(true))
      } else {
        dispatch(setIntroPopup(false))
      }
    }
  }, [])

  useEffect(() => {
    if (!rankingPopup) {
      setTop100({ list: [], title: "", type: "" })
    }
  }, [rankingPopup])

  useEffect(async () => {
    //로그인시 getdata
    getData()
    if (!isLogin) {
      checkLoginFn()
      initState()
    } else {
      if (inviteCheck) inviteFn()
      let type = await SignApis.postAuthentication()
      await getMyProfileAction({ managerUserSeq: localStorgeGet("loginUserSeq"), managerUserType: type?.data?.loginUserType || "" })
    }
  }, [isLogin])

  useEffect(() => {
    if (isLogin == true && studyMakePopup == false) {
      getData()
    }
  }, [studyMakePopup])

  useEffect(() => {
    if (isLogin) {
      //주변지역 목록 가져오기
      Object.keys(exploringData).length > 0 && addrCheckFn()
    }
  }, [exploringData])

  useEffect(() => {
    getData()
  }, [keyword, location])

  const messageDataFn = e => {
    if (!window.ReactNativeWebView) return false
    let data = JSON.parse(e.data)
    setEmpty(moment())
    if (data.type == "BACK") {
      dispatch(setJitsiClose(true))
      setTimeout(() => {
        dispatch(setJitsiClose(false))
        dispatch(setJitsiPopup(false))
        dispatch(setJitsiInfo({ name: "", seq: "", roomInfo: {} }))
      }, 3000)
      dispatch(setRankingPopup(false))
      dispatch(setLoginPopup(false))
      dispatch(setSignUpPopup(false))
      dispatch(setStudyMakePopup(false))
      dispatch(setStudyInfoPopup({ open: false, data: {} }))
      dispatch(setProfilePopup({ open: false, data: {} }))
      dispatch(setConsultantProfilePopup({ open: false, data: {} }))
    }
  }

  //메세지 리스너 세팅
  useEffect(async () => {
    await getPromotionContents() //프로모션 광고
    window.addEventListener("message", messageDataFn)
    document.addEventListener("message", messageDataFn)

    return () => {
      document.removeEventListener("message", messageDataFn)
      window.removeEventListener("message", messageDataFn)
    }
  }, [])

  return (
    <Layout barPc={horizonBottomPc} barMo={horizonBottomMo}>
      <SEO title={studyMakePopup ? "스터디 만들기" : "스터디 탐색"} />
      <IntroPopup />
      <GatewayPopup />
      <ModalPwReqPopup
        setPw={setPw}
        pw={pw}
        open={openPwReqPopup.open}
        joinFn={() => joinPwCheckFn()}
        reqFn={reqFn}
        onClose={() => {
          setOpenPwReqPopup({ open: false, data: {} })
          setPw("")
        }}
      />
      <ModalPointPopup open={false} contents={{ point: 5, totalPoint: 123456 }} onClose={() => {}} onClickBtn={() => {}} />
      <ModalPopup open={openClosePopup} onClose={() => setOpenClosePopup(false)} contents={"종료된 스터디 또는 스터디 참여시간이 아닙니다."} />
      <RankingPopup list={top100.list} title={top100.title} type={top100.type} />

      {Object.keys(exploringData).length != 0 && (
        <div className="container exploring-container">
          {/* 데스크탐 롤링 광고 */}
          <NonDesktop>
            <div className="ad-rolling-div">
              <div className="ad-rolling-view">
                <div className="rolling-button">
                  <button
                    className="btn card-left-button"
                    onClick={() => {
                      if (swiperIndexTopAd == 0) return
                      setSwiperIndexTopAd(prev => prev - 1)
                    }}
                  >
                    <img src={img_schedule_left_white} alt="left arrow img" />
                  </button>
                  <button
                    className="btn card-right-button"
                    onClick={() => {
                      if (swiperIndexTopAd >= rollingTopPcList.length - 1) return
                      setSwiperIndexTopAd(prev => prev + 1)
                    }}
                  >
                    <img src={img_schedule_right_white} alt="right arrow img" />
                  </button>
                </div>
                <div className="card-swiper-view">
                  {rollingTopPcList.length != 0 && (
                    <AutoPlaySwipeableViews
                      // autoPlay
                      autoplay={true}
                      interval={3000}
                      index={swiperIndexTopAd}
                      onChangeIndex={idx => setSwiperIndexTopAd(idx)}
                    >
                      {rollingTopPcList.map((i, idx) => (
                        <img
                          onClick={async () => {
                            let result = await putContentsPromotionView({ promotionAdSeq: rollingTopPc[0] })
                            if (result) {
                              navigate(i.pageUrl)
                            }
                          }}
                          className="ad-rolling-img"
                          key={idx}
                          src={i.imageUrl}
                        />
                      ))}
                    </AutoPlaySwipeableViews>
                  )}
                </div>
              </div>
            </div>
          </NonDesktop>
          <Tablet>
            <div className="ad-rolling-div">
              <div className="ad-rolling-view">
                <div className="rolling-button"></div>
                {rollingTopMoList.length > 1 && (
                  <div className="rolling-indicator-view">
                    <p>
                      {swiperIndexTopAd + 1} / {rollingTopMoList.length}
                    </p>
                  </div>
                )}
                <div className="card-swiper-view">
                  {rollingTopMoList.length != 0 && (
                    <AutoPlaySwipeableViews
                      autoplay={true}
                      interval={3000}
                      enableMouseEvents
                      index={swiperIndexTopAd}
                      onChangeIndex={idx => setSwiperIndexTopAd(idx)}
                    >
                      {rollingTopMoList.map((i, idx) => (
                        <img
                          onClick={async () => {
                            let result = await putContentsPromotionView({ promotionAdSeq: rollingTopMo[0] })
                            if (result) {
                              navigate(i.pageUrl)
                            }
                          }}
                          className="ad-rolling-img"
                          key={idx}
                          src={i.imageUrl}
                        />
                      ))}
                    </AutoPlaySwipeableViews>
                  )}
                </div>
              </div>
            </div>
          </Tablet>
          <div className="exploring-header">
            <p className="exploring-text">렛미업과 함께 시작하는 스마트한 공부 첫 단계</p>
            <p className="exploring-title">내 주변근처 스터디 탐색</p>
            <div className="exploring-dropdown">
              <select
                className="form-select select-css"
                onChange={e => {
                  setLocation(e.target.value)
                }}
              >
                {isLogin == false ? (
                  <option style={{ textAlign: "left" }} className="option-css" value="전체">
                    전체
                  </option>
                ) : (
                  exploringData.userStudyLocations != undefined &&
                  ["전체", ...LocArr].map((i, idx) => (
                    <option style={{ textAlign: "left" }} key={idx} className="option-css" value={i}>
                      {i}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div className="exploring-keyword-swiper">
              <Swiper shouldSwiperUpdate slidesPerView="auto" freeMode>
                {exploringData.keywordTop5.length != 0 &&
                  exploringData.keywordTop5.map((i, idx) => (
                    <p
                      onClick={() => {
                        if (keyword === i.tag) setKeyword("")
                        else setKeyword(i.tag)
                      }}
                      key={idx}
                      className={`exploring-keyword-item ${keyword === i.tag ? "active" : ""}`}
                    >
                      {i.tag}
                    </p>
                  ))}
              </Swiper>
            </div>
          </div>

          {/* 필터 */}
          <div className="exploring-card-filter-container">
            <p>총 {exploringData.totalCount || 0}개의 스터디그룹</p>
            <button className="btn">
              {/* <img src={img_exploring_filter} alt="filter img" />
              <p>필터</p> */}
            </button>
          </div>

          {/* 스터디 */}
          <div className="row exploring-card-container">
            {exploringData.studies.length != 0 &&
              exploringData.studies.map((i, idx) => {
                if (idx > 5) return false
                return (
                  <ColStudyItem
                    key={idx}
                    data={i}
                    onClickStudy={async e => {
                      e.stopPropagation()
                      if (isLogin == false) {
                        dispatch(setLoginPopup(true))
                        return false
                      } else {
                        if (
                          i.isParticipation.indexOf("종료") != -1 ||
                          moment().format("YYYY-MM-DD HH:mm:ss") > moment(i.dateEnd).format("YYYY-MM-DD HH:mm:ss") ||
                          moment().format("YYYY-MM-DD HH:mm:ss") < moment(i.dateStart).format("YYYY-MM-DD HH:mm:ss")
                        ) {
                          setOpenClosePopup(true)
                          return false
                        }
                        if (i.isUsingPassword == "Y" && i.isOwner == "N") {
                          setOpenPwReqPopup({ open: true, data: i })
                        } else {
                          const rq_result = await api.post(`/study/participation`, {
                            token: true,
                            body: { studySeq: i.studySeq, isInvite: "N", inviter: "" },
                          })
                          if (rq_result.status === 200) {
                            dispatch(
                              setJitsiInfo({
                                name: i.title,
                                seq: i.studySeq,
                                roomInfo: rq_result.data.data.studyMemberUserInfo,
                                aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
                              })
                            )
                            // navigate("/jitsi")
                            dispatch(setJitsiPopup(true))
                          }
                        }
                      }
                    }}
                  />
                )
              })}
          </div>

          {/* 순위 */}
          <div className="exploring-rank-container">
            {Object.keys(exploringData).length > 0 && (
              <Desktop>
                <RankItem
                  title="베스트 스터디 그룹 순위"
                  list={Object.keys(exploringData).length > 0 && exploringData.studyStatistics}
                  onClick={() => {
                    setTop100({ list: exploringData.studyStatistics || [], title: "베스트 스터디 그룹 순위", type: "" })
                    dispatch(setRankingPopup(true))
                  }}
                />
                <RankItem
                  title="누적 공부시간 순위"
                  list={Object.keys(exploringData).length > 0 && exploringData.userStudyStatistics}
                  onClick={() => {
                    setTop100({ list: exploringData.userStudyStatistics || [], title: "누적 공부시간 순위", type: "" })
                    dispatch(setRankingPopup(true))
                  }}
                />
                <RankItem
                  title="Study Keyword 순위"
                  list={Object.keys(exploringData).length > 0 && exploringData.keywordStatistics}
                  onClick={() => {
                    setTop100({ list: exploringData.keywordStatistics || [], title: "Study Keyword 순위", type: "keyword" })
                    dispatch(setRankingPopup(true))
                  }}
                  type="keyword"
                />
              </Desktop>
            )}
            {Object.keys(exploringData).length > 0 && (
              <Mobile>
                <SwipeableViews index={swiperIndex} onChangeIndex={index => setSwiperIndex(index)}>
                  <RankItem
                    title="베스트 스터디 그룹 순위"
                    list={Object.keys(exploringData).length > 0 && exploringData.studyStatistics}
                    onClick={() => {
                      setTop100({ list: exploringData.studyStatistics || [], title: "베스트 스터디 그룹 순위", type: "" })
                      dispatch(setRankingPopup(true))
                    }}
                  />
                  <RankItem
                    title="누적 공부시간 순위"
                    list={Object.keys(exploringData).length > 0 && exploringData.userStudyStatistics}
                    onClick={() => {
                      setTop100({ list: exploringData.userStudyStatistics || [], title: "누적 공부시간 순위", type: "" })
                      dispatch(setRankingPopup(true))
                    }}
                  />
                  <RankItem
                    title="Study Keyword 순위"
                    list={Object.keys(exploringData).length > 0 && exploringData.keywordStatistics}
                    onClick={() => {
                      setTop100({ list: exploringData.keywordStatistics || [], title: "Study Keyword 순위", type: "keyword" })
                      dispatch(setRankingPopup(true))
                    }}
                    type="keyword"
                  />
                </SwipeableViews>
              </Mobile>
            )}
          </div>

          <Mobile>
            <div className="exploring-rank-swiper-bullet">
              <button
                className={`btn exploring-rank-swiper-bullet-item ${swiperIndex === 0 ? "active" : ""}`}
                onClick={() => setSwiperIndex(0)}
              ></button>
              <button
                className={`btn exploring-rank-swiper-bullet-item ${swiperIndex === 1 ? "active" : ""}`}
                onClick={() => setSwiperIndex(1)}
              ></button>
              <button
                className={`btn exploring-rank-swiper-bullet-item ${swiperIndex === 2 ? "active" : ""}`}
                onClick={() => setSwiperIndex(2)}
              ></button>
            </div>
          </Mobile>

          {/* 스터디 */}
          <div className="row exploring-card-container">
            {exploringData.studies.length != 0 &&
              exploringData.studies.map((i, idx) => {
                if (idx < 6) return false
                return (
                  <ColStudyItem
                    key={idx}
                    data={i}
                    onClickStudy={async e => {
                      e.stopPropagation()
                      if (isLogin == false) {
                        dispatch(setLoginPopup(true))
                        return false
                      } else {
                        if (
                          i.isParticipation.indexOf("종료") != -1 ||
                          moment().format("YYYY-MM-DD HH:mm:ss") > moment(i.dateEnd).format("YYYY-MM-DD HH:mm:ss") ||
                          moment().format("YYYY-MM-DD HH:mm:ss") < moment(i.dateStart).format("YYYY-MM-DD HH:mm:ss")
                        ) {
                          setOpenClosePopup(true)
                          return false
                        }
                        if (i.isUsingPassword == "Y" && i.isOwner == "N") {
                          setOpenPwReqPopup({ open: true, data: i })
                        } else {
                          const rq_result = await api.post(`/study/participation`, {
                            token: true,
                            body: { studySeq: i.studySeq, isInvite: "N", inviter: "" },
                          })
                          if (rq_result.status === 200) {
                            dispatch(
                              setJitsiInfo({
                                name: i.title,
                                seq: i.studySeq,
                                roomInfo: rq_result.data.data.studyMemberUserInfo,
                                aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
                              })
                            )
                            dispatch(setJitsiPopup(true))
                          }
                        }
                      }
                    }}
                  />
                )
              })}
            {/* 롤링 ad bottom veiw */}
            <NonDesktop>
              <div className="ad-rolling-bottom">
                <div className="ad-rolling-view">
                  <div className="card-swiper-view">
                    {rollingBottomPcList.length != 0 && (
                      <AutoPlaySwipeableViews
                        autoplay={true}
                        interval={3000}
                        index={swiperIndexBottomAd}
                        onChangeIndex={idx => setSwiperIndexBottomAd(idx)}
                      >
                        {rollingBottomPcList.map((i, idx) => (
                          <img
                            onClick={async () => {
                              let result = await putContentsPromotionView({ promotionAdSeq: rollingBottomPc[0] })
                              if (result) {
                                navigate(i.pageUrl)
                              }
                            }}
                            className="ad-rolling-img"
                            key={idx}
                            src={i.imageUrl}
                          />
                        ))}
                      </AutoPlaySwipeableViews>
                    )}
                  </div>
                </div>
                <div className="ad-rolling-bottom-indicator-view">
                  {rollingBottomPcList.map((i, idx) => (
                    <button
                      className={`btn ad-rolling-bottom-indicator-item ${swiperIndexBottomAd === idx ? "active" : ""}`}
                      key={idx}
                      onClick={() => setSwiperIndexBottomAd(idx)}
                    ></button>
                  ))}
                </div>
              </div>
            </NonDesktop>
            <Tablet>
              <div className="ad-rolling-bottom">
                <div className="ad-rolling-view">
                  <div className="card-swiper-view">
                    {rollingBottomMoList.length != 0 && (
                      <AutoPlaySwipeableViews
                        autoplay={true}
                        interval={3000}
                        index={swiperIndexBottomAd}
                        onChangeIndex={idx => setSwiperIndexBottomAd(idx)}
                      >
                        {rollingBottomMoList.map((i, idx) => (
                          <img
                            onClick={async () => {
                              let result = await putContentsPromotionView({ promotionAdSeq: rollingBottomMo[0] })
                              if (result) {
                                navigate(i.pageUrl)
                              }
                            }}
                            className="ad-rolling-img"
                            key={idx}
                            src={i.imageUrl}
                          />
                        ))}
                      </AutoPlaySwipeableViews>
                    )}
                  </div>
                </div>
                <div className="ad-rolling-bottom-indicator-view">
                  {rollingBottomMoList.map((i, idx) => (
                    <button
                      className={`btn ad-rolling-bottom-indicator-item ${swiperIndexBottomAd === idx ? "active" : ""}`}
                      key={idx}
                      onClick={() => setSwiperIndexBottomAd(idx)}
                    ></button>
                  ))}
                </div>
              </div>
            </Tablet>
          </div>
          {/* card ad bottom veiw */}
          <NonDesktop>
            <div className="d-flex justify-content-between ad-card-view">
              {cardHoldPc.map((i, idx) => {
                if (idx == 0) return false
                return (
                  <img
                    onClick={async () => {
                      let result = await putContentsPromotionView({ promotionAdSeq: cardHoldPc[0] })
                      if (result) {
                        navigate(i.pageUrl)
                      }
                    }}
                    src={i.imageUrl}
                    key={idx}
                  />
                )
              })}
            </div>
          </NonDesktop>
          <Tablet>
            <div className="d-flex justify-content-between ad-card-view">
              {cardHoldMo.map((i, idx) => {
                if (idx == 0) return false
                return (
                  <img
                    onClick={async () => {
                      let result = await putContentsPromotionView({ promotionAdSeq: cardHoldMo[0] })
                      if (result) {
                        navigate(i.pageUrl)
                      }
                    }}
                    src={i.imageUrl}
                    key={idx}
                    className="ms-2 me-2"
                  />
                )
              })}
            </div>
          </Tablet>
          {/* <Tablet>
            <div className="d-flex justify-content-between ad-card-view">
              {[1, 2, 3].map((i, idx) => {
                if (idx == 0) return false
                return <img src={cardPc} key={idx} className="ms-2 me-2" />
              })}
            </div>
          </Tablet> */}
          {/* 최하단 검정 버튼들 */}
          <div className="exploring-bottom-button-container">
            <div className="exploring-bottom-button-box">
              <div>
                <img src={img_exploring_bottom_button_1} alt="bottom button img 1" />
                <p>{`렛미업\n민감도 안내`}</p>
              </div>
            </div>
            <div className="exploring-bottom-button-box">
              <div>
                <img src={img_exploring_bottom_button_2} alt="bottom button img 2" />
                <p>{`렛미업\n학습규칙 알아보기`}</p>
              </div>
            </div>
            <div className="exploring-bottom-button-box">
              <div>
                <img src={img_exploring_bottom_button_3} alt="bottom button img 3" />
                <p>{`렛미업\nTO-DO 스케줄관리`}</p>
              </div>
            </div>
            <div className="exploring-bottom-button-box">
              <div>
                <img src={img_exploring_bottom_button_4} alt="bottom button img 4" />
                <p>{`렛미업\n추천 온라인 컨텐츠`}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default IntroContainer(MyContainer(StudyMakeContainer(SignContainer(ExploringContainer(Exploring)))))
