import React from "react"
import { Desktop, Mobile } from "../layouts/mediaQuery"
import img_exploring_right_arrow from "../../images/img_exploring_right_arrow.svg"
import UP from "../../images/UP.svg"
import DOWN from "../../images/DOWN.svg"
import KEEP from "../../images/KEEP.svg"
import img_ranking_is_mine from "../../images/img_ranking_is_mine.svg"

const RankItem = ({ title = "", list = [], onClick, type = "" }) => {
  return (
    <div className="exploring-rank-box">
      <div>
        <a
          onClick={e => {
            e.preventDefault()
            onClick()
          }}
        >
          <Desktop>
            {title} <img src={img_exploring_right_arrow} alt="right arrow img" />
          </Desktop>
          <Mobile>
            {title}
            <div>
              <p>더보기</p>
              <img src={img_exploring_right_arrow} alt="right arrow img" />{" "}
            </div>
          </Mobile>
        </a>
        <div className="exploring-rank-list">
          {list.length != 0
            ? list.map((i, idx) => {
                if (idx < 5) {
                  return (
                    <div key={idx} className="exploring-rank-item">
                      <div className="text-box">
                        {i.isMine == 1 ? <img className="is-mine" src={img_ranking_is_mine} alt="ismine img" /> : null}
                        <p className="ranking">{idx + 1}</p>
                        <p className="title">{i.title || i.nickname || i.tag}</p>
                      </div>
                      {type == "" ? (
                        <p className="time">{i.totalTime}</p>
                      ) : (
                        <div className="up-down">
                          <p className="fluctuation">{i.rankingFluctuation || "-"}</p>
                          <div className="img-wrapper">
                            <img src={i.rankingUpDown == "KEEP" ? KEEP : i.rankingUpDown == "UP" ? UP : DOWN}></img>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                } else {
                  return <div key={idx}></div>
                }
              })
            : ["", "", "", "", ""].map((i, idx) => {
                return (
                  <div key={idx} className="exploring-rank-item">
                    <div>
                      <p>{idx + 1}</p>
                      <p></p>
                    </div>
                  </div>
                )
              })}
        </div>
      </div>
    </div>
  )
}

export default RankItem
